import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import {
  Modal,
  AlertIcon,
  InfoBox,
  BulletedList,
} from "@ayoconnect/common-ui-components";
import { STATIC_ASSETS } from "../../utils/staticAssets";
import { ModalWrapper, CloseIconGrid, ModalHeader } from "./modal.styles";
import LocalData from "../../utils/localization";
import LocalizedStrings from "react-localization";
import { useSelector } from "react-redux";
import { useUpdateStatus } from "../../customHook/useUpdateStatus";
import { withStyles } from "@mui/styles";

const styles = () => ({
  statusBlock: {
    marginBottom: "2.25rem",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  statusBlockInner: {
    display: "flex",
    gap: "1rem",
    alignItems: "center",
  },
  statusIcon: {
    height: "64px",
    width: "64px",
  },
  updateStatusBtnText: {
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "20px",
    color: "#333",
  },
  updateTrxnStatusText: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "24px",
    color: "#333",
  },
  cancelConfirmText: {
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "20px",
    color: "#333",
  },
  confirmText: {
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "20px",
  },
  statusBtnText: {
    fontFamily: "Inter",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "18px",
    color: "#333",
  },
  statusText: {
    fontFamily: "Inter",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "28px",
    color: "#333",
  },
  updateStatusText: {
    cursor: "pointer",
    minHeight: "40px",
    padding: "0.5rem 0.75rem",
    border: "1px solid rgba(0,0,0,0.1)",
    borderRadius: "8px",
  },
  updateStatusBlock: {
    padding: "1rem",
    width: "100%",
    border: "1px solid #4EA7FF",
    borderRadius: "8px",
    marginBottom: "1rem",
  },
  updateTextAndInfo: {
    display: "flex",
    gap: "1rem",
    alignItems: "center",
    marginBottom: "3rem",
  },
  infoIcon: {
    height: "32px",
    width: "32px",
  },
  statusButtonBlock: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "3rem",
  },
  statusButton: {
    padding: "0.5rem 1rem",
    display: "flex",
    gap: "1rem",
    width: "48%",
    marginRight: "0.5rem",
    border: "1px solid #DFE2E6",
    borderRadius: "8px",
  },
  bankRefNumInput: {
    width: "100%",
    borderRadius: "8px",
    marginTop: "0.5rem",
    height: "48px",
    padding: "0.75rem",
    marginBottom: "1rem",
    border: "1px solid #ddd",
  },
  cancelConfirmBlock: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  cancelWrapper: {
    cursor: "pointer",
    border: "1px solid #DFE2E6",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "48%",
    marginRight: "0.5rem",
    padding: "0.5rem",
  },
  confirmWrapper: {
    cursor: "pointer",
    border: "1px solid #DFE2E6",
    backgroundColor: "#19A9E1",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "48%",
    padding: "0.5rem",
    "& div": {
      color: "#fff",
    },
  },
  disabledConfirmWrapper: {
    cursor: "not-allowed",
    border: "1px solid #DFE2E6",
    backgroundColor: "#F8F8F8",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "48%",
    padding: "0.5rem",
    "& div": {
      color: "#ccc",
    },
  },
  rowDataHeader: {
    fontSize: "16px",
    width: "100%",
    backgroundColor: "#E8F7FC",
    borderRadius: "8px",
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingLeft: "10px",
    fontWeight: 500,
    display: "block",
    marginBottom: "1rem",
  },
  rowDataWrapper: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    padding: "0px 10px",
    fontSize: "14px",
    margin: "14px 0px",
    borderBottom: "1px solid #F2F2F2",
  },
  label1Wrapper: {
    marginBottom: "1rem",
    "& p": {
      margin: "16px 0px 6px",
    },
    width: "50%",
  },
  labelText: {
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "20px",
    color: "#444",
  },
  valueText: {
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "20px",
    color: "#333",
  },
  label2Wrapper: {
    width: "48%",
    marginLeft: "2%",
    margin: 0,
    "& p": {
      margin: "16px 0px 6px",
    },
  },
  label2Text: {
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "16px",
    color: "#333333",
  },
  modalHeading: {
    width: "100%",
    fontFamily: "Inter",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "32px",
    color: "#333",
  },
  modalLoader: {
    height: "64px",
    width: "64px",
  },
  modalLoaderWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
});

const TransactiondetailsModal = (props) => {
  const {
    refreshTable,
    handleUpdateResponse,
    displayData,
    setClose,
    loader,
    showLoader,
    setErrorText,
    setOpenErrorModal,
  } = props;
  const classes = props.classes;
  const [editing, setEditing] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [trxnRefNumber, setTrxnRefNumber] = useState("");
  const [justUpdated, setJustUpdated] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const { currentLanguage } = useSelector((state) => state.languageData);
  const strings = new LocalizedStrings(LocalData);
  strings.setLanguage(currentLanguage);

  const { UpdateTransactionStatus } = useUpdateStatus(
    showLoader,
    setErrorText,
    setOpenErrorModal,
    handleUpdateResponse,
    {
      partnerReferenceNumber: displayData[0]?.partnerRefNo,
      customerId: displayData[0]?.customerId,
    },
    setJustUpdated
  );

  const handleClose = () => {
    if (justUpdated) {
      const param = {
        name: displayData[0].merchantName,
        id: displayData[0].merchantCode,
      };
      refreshTable(param);
    }
    setClose(false);
  };

  const infoIcon =
    "https://storage.googleapis.com/dd-ui-static-dev/images/exclaimation-circle2.svg";

  const updateTrxnStatus = () => {
    const statuses = [
      "processing",
      "success",
      "refunded",
      "canceled",
      "failed",
    ];
    const statusParam = statuses.indexOf(selectedStatus.toLowerCase());
    UpdateTransactionStatus(
      "/of-admin-dashboard-bi-fast/web/v1/disbursement/status",
      statusParam,
      displayData[0].partnerRefNo,
      trxnRefNumber,
      setEditing
    );
  };

  const handleRadioButton = (val) => {
    setSelectedStatus(val);
  };

  const checkDisabled = () => {
    if (
      displayData[0]?.status?.toLowerCase() === "processing" ||
      displayData[0]?.status?.toLowerCase() === "failed"
    ) {
      if (selectedStatus == "") {
        return true;
      } else if (selectedStatus === "success" && trxnRefNumber === "") {
        return true;
      } else {
        return false;
      }
    } else {
      if (selectedStatus == "") {
        return true;
      } else {
        return false;
      }
    }
  };

  return (
    <Box>
      {showConfirmModal ? (
        <Modal
          open={true}
          title={strings.Confirmation}
          subHeading={null}
          cancel={true}
          children={[
            <Box display={"flex"} gap={"16px"} flexDirection={"column"}>
              <AlertIcon height={"64px"} />
              <Typography
                fontSize={"30px"}
                fontWeight={600}
                lineHeight={"39.21px"}
                letterSpacing={0}
                fontFamily={"Inter, sans-serif"}
                variant={"body5"}
                paddingX={'95px'}
                textAlign={"center"}
              >
                {strings.TransferHistory.AreYouSure}
              </Typography>
            </Box>,
            <InfoBox type="alert">
              <BulletedList
                items={[
                  //strings.TransferHistory.ConfirmPoints.point1+ " "+displayData[0]?.status,
                  strings.TransferHistory.ConfirmPoints.point2,
                ]}
              />
            </InfoBox>,
          ]}
          onClose={() => {
            setShowConfirmModal(false);
          }}
          actions={[
            {
              label: strings.Cancel,
              buttonProps: { variant: "outlined", maxWidth: true },
              onClick: () => {
                setShowConfirmModal(false);
              },
            },
            {
              label: strings.TransferHistory.Update,
              onClick: () => {
                setShowConfirmModal(false);
                updateTrxnStatus();
              },
              buttonProps: {
                style: { textTransform: "capitalize" },
                variant: "contained",
                maxWidth: true,
              },
            },
          ]}
        ></Modal>
      ) : (
        <ModalWrapper
          width="550px"
          data-testid="transfer-history-details-modal-wrapper"
          container
          spacing={2}
        >
          <ModalHeader
            style={{
              marginBottom: "2.25rem",
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography className={classes.modalHeading}>
              {strings.TransferHistory.Transaction_Details}
            </Typography>
            <CloseIconGrid
              id="transfer-history-close-icon"
              data-testid="transfer-history-close-icon"
              item
              xs={12}
              alignItems={"right"}
            >
              <img
                id="close-icon"
                src={STATIC_ASSETS.CLOSE_ICON}
                alt="close-icon"
                onClick={handleClose}
              />
            </CloseIconGrid>
          </ModalHeader>
          {loader && (
            <div
              id="modal-loader-image"
              className={classes.modalLoaderWrapper}
              data-testid="modal-loader-image"
            >
              <img
                className={classes.modalLoader}
                src={STATIC_ASSETS.LOADER}
                alt={"loader"}
              />
            </div>
          )}
          {!loader && (
            <>
              {!editing && (
                <div className={classes.statusBlock}>
                  <div className={classes.statusBlockInner}>
                    <img
                      src={displayData[0]?.statusIcon}
                      className={classes.statusIcon}
                    />
                    <div className={classes.statusText}>
                      {justUpdated
                        ? strings.TransferHistory.trxnUpdated
                        : strings.TransferHistory.Transaction}{" "}
                      {displayData[0]?.status}
                    </div>
                  </div>
                  {displayData[0]?.status?.toLowerCase() === "processing" && (
                    <div
                      id="update-status-button"
                      onClick={() => {
                        setEditing(true);
                      }}
                      className={classes.updateStatusText}
                    >
                      <div className={classes.updateStatusBtnText}>
                        {strings.TransferHistory.UpdateStatus}
                      </div>
                    </div>
                  )}
                </div>
              )}
              {editing && (
                <div className={classes.updateStatusBlock}>
                  <div className={classes.updateTextAndInfo}>
                    <img src={infoIcon} className={classes.infoIcon} />
                    <div className={classes.updateTrxnStatusText}>
                      {strings.TransferHistory.UpdateTrxnStatus}
                    </div>
                  </div>
                  <div className={classes.statusButtonBlock}>
                    {displayData[0]?.status?.toLowerCase() !== "success" ? (
                      <div className={classes.statusButton}>
                        <input
                          id="success-radio-button"
                          checked={selectedStatus === "success"}
                          type="radio"
                          onChange={() => handleRadioButton("success")}
                        />
                        <div className={classes.statusBtnText}>
                          {strings.TransferHistory.Success}
                        </div>
                      </div>
                    ) : (
                      <div className={classes.statusButton}>
                        <input
                          id="refunded-radio-button"
                          checked={selectedStatus === "refunded"}
                          type="radio"
                          onChange={() => {
                            setTrxnRefNumber("");
                            handleRadioButton("refunded");
                          }}
                        />
                        <div className={classes.statusBtnText}>
                          {strings.TransferHistory.Refund}
                        </div>
                      </div>
                    )}
                    {displayData[0]?.status?.toLowerCase() === "processing" ? (
                      <div className={classes.statusButton}>
                        <input
                          id="failed-radio-button"
                          checked={selectedStatus === "failed"}
                          type="radio"
                          onChange={() => {
                            setTrxnRefNumber("");
                            handleRadioButton("failed");
                          }}
                        />
                        <div className={classes.statusBtnText}>
                          {strings.TransferHistory.Failed}
                        </div>
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                  {(displayData[0]?.status?.toLowerCase() === "processing" ||
                    displayData[0]?.status?.toLowerCase() === "failed") &&
                    selectedStatus === "success" && (
                      <>
                        <div>{strings.TransferHistory.BankRefNo}</div>
                        <input
                          id="bank-ref-number-input"
                          placeholder="Enter Bank Reference number"
                          className={classes.bankRefNumInput}
                          value={trxnRefNumber}
                          onChange={(e) => setTrxnRefNumber(e.target.value)}
                        />
                      </>
                    )}
                  <div className={classes.cancelConfirmBlock}>
                    <div
                      id="transfer-cancel-button"
                      onClick={() => {
                        setEditing(false);
                        setSelectedStatus("");
                      }}
                      className={classes.cancelWrapper}
                    >
                      <div className={classes.cancelConfirmText}>
                        {strings.TransferHistory.Cancel}
                      </div>
                    </div>
                    <div
                      id="confirm-button"
                      onClick={() => {
                        if (!checkDisabled()) {
                          setShowConfirmModal(true);
                          //updateTrxnStatus();
                        }
                      }}
                      className={
                        checkDisabled()
                          ? classes.disabledConfirmWrapper
                          : classes.confirmWrapper
                      }
                    >
                      <div className={classes.confirmText}>
                        {strings.TransferHistory.Confirm}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {displayData?.map((rowdata, index) => (
                <div style={{ width: "100%" }}>
                  <div key={index}>
                    <span className={classes.rowDataHeader}>
                      {rowdata.header}
                    </span>
                  </div>
                  <div>
                    {rowdata.subData.map((subdata, key) => (
                      <div className={classes.rowDataWrapper} key={key}>
                        <div className={classes.label1Wrapper}>
                          <span className={classes.labelText}>
                            {subdata.label1}
                          </span>
                          <div
                            className={classes.valueText}
                            style={{ color: subdata.statusColor1 }}
                          >
                            {subdata.value1}
                          </div>
                        </div>
                        <div className={classes.label2Wrapper}>
                          <span className={classes.label2Text}>
                            {subdata.label2}
                          </span>
                          <div
                            className={classes.valueText}
                            style={{ color: subdata.statusColor2 }}
                          >
                            {subdata.value2}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </>
          )}
        </ModalWrapper>
      )}
    </Box>
  );
};

export default withStyles(styles)(TransactiondetailsModal);
