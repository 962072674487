import { Box, Button, Grid, Typography, useTheme } from "@mui/material"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Controls } from "../../controls"
import { STATIC_ASSETS } from "../../utils/staticAssets";
import { ModalWrapper, ModalHeader, ModalTextWrapperWithBg, 
    ModalTextWrapper,
    VcWalletBox, CloseIconGrid,
    StyledTextField,
    RoundedIconWrapper
 } from "./modal.styles"
import LocalData from "../../utils/localization";
import LocalizedStrings from "react-localization";
import { StyledInputLabel } from "../../controls/inputs/BaseTextfield.styles"
import { formatAmount, formatRupiah } from "../../utils/utilFunctions";
import { VcTopupTrxType } from "../../utils/const";
import { StyledRefreshBtn } from "../cards/cards.styles";
import { Components } from "../../components/index";

const prependIcon = (icon) => {
    return (
        <img style={{marginRight: '1rem'}} src={icon} alt="icon" />
    )
} 
const VCTopupFormModal = (props) => {
    const { vcMerchantRetryAfter } = useSelector((state) => state.rateLimiterData);
    const { currentLanguage } = useSelector((state) => state.languageData);
    const theme = useTheme()
    const strings = new LocalizedStrings(LocalData);
    strings.setLanguage(currentLanguage);
    const { 
        merchantName, 
        merchantBalance,
        isLoadingBalance,
        modalTextAboveButton,
        topupModalKind=VcTopupTrxType.topup.id,
        topupInitialTrxID=null,
        topupInitialAmount=0,
        topupReversedAmount=0,

        // function
        refreshBalance,
        setCurrentModal, 
        setData, 
        setClose
    } = props
    const initialAmount = topupModalKind === VcTopupTrxType.fullReversal.id ? topupInitialAmount : 0
    const [submitModalContent, setSubmitModalContent] = useState({
        rupiahAmount: formatAmount(initialAmount),
        amount: initialAmount,
        description: '',
        transactionID: topupInitialTrxID,
        transactionType: topupModalKind
    })
    
    const [state, setState] = useState({
        availableBalance: 0,
        maxRefundAmount: 0,
        isBalanceLowThanFullAmount: false,
        disableRefreshBalance: false
    });
    const dispatch = useDispatch()
    
    const updateSubmitModalContent = (newState) => {
        setSubmitModalContent((prev) => {
            return {
                ...prev,
                ...newState
            }
        })
    }
    const updateState = (newState) => {
        setState(prev => {
            return {
                ...prev,
                ...newState
            }
        })
    }

    const handleClose = () => {
        setClose(false)
        dispatch(setCurrentModal(0))
    }

    const getMaxTopupAmount = () => {
        // 10.000.000.000
        const maxAmount = Math.pow(10, 10);
        return maxAmount;
    }

    const handleAmount = (e) => {
        let strValue = e.target.value
        if(! strValue) {
            strValue = '0'
        } // endif - handle NaN error issue

        const rawValue = parseInt(strValue.replace(/\D+/g, ''))
        if(rawValue > getMaxTopupAmount()) {
            return false
        } // endif
        updateSubmitModalContent({ amount: rawValue, rupiahAmount: formatAmount(rawValue) })
    }

    const handleDesc = (e) => {
        updateSubmitModalContent({ description: e.target.value })
    }

    const handleTrxID = (e) => {
        updateSubmitModalContent({ transactionID: e.target.value })
    }

    const onSubmit = () => {
        dispatch(setData(submitModalContent))
        dispatch(setCurrentModal(1))
    }

    const isTopupReversal = () => {
        return parseInt(submitModalContent.transactionType) !== VcTopupTrxType.topup.id
    }

    // check amount for reversal only
    const isAmountValid = (partialOnly=false) => {
        let result = submitModalContent.amount <= state.maxRefundAmount
        if(partialOnly && (topupModalKind !== VcTopupTrxType.partialReversal.id)) {
            // disable error warning if param true, and trxType not partial reversal
            return true
        } // endif
        return result
    }

    const isInsufficientBalance = () => {
        return (topupInitialAmount > merchantBalance) && (topupModalKind === VcTopupTrxType.fullReversal.id)
    }

    const validateMinMaxLength = (str='', min=5, max=20) => {
        if(! str) {
            return false
        } // endif
        return str.length >= min && str.length <= max
    }

    const textFieldMinMaxValidator = (str='', description=false) => {
        if(! str) {
            return true
        } // endif
        return description ? validateMinMaxLength(str, 5, 100) : validateMinMaxLength(str)
    }

    const isAllFieldCompleted = () => {
        let result = submitModalContent.amount 
            && validateMinMaxLength(submitModalContent.transactionID) 
            && validateMinMaxLength(submitModalContent.description, 5, 100)

        if(topupModalKind !== VcTopupTrxType.topup.id) {
            result = result && isAmountValid()
                && (!isLoadingBalance)
        } // endif
        
        return result
    }

    useEffect(() => {
        if(vcMerchantRetryAfter > 0) {
            updateState({disableRefreshBalance: true})
        } else {
            updateState({disableRefreshBalance: false})
        } // endif
    }, [vcMerchantRetryAfter]);

    useEffect(() => {
        const setBalanceAndMaxAmount = async () => {
            const refundableBalance = topupInitialAmount - topupReversedAmount
            const availableBalance = merchantBalance
            const maxRefundAmount = refundableBalance <= availableBalance ? refundableBalance : availableBalance
            const isBalanceLowThanFullAmount = availableBalance < refundableBalance

            updateState({
                availableBalance,
                maxRefundAmount,
                isBalanceLowThanFullAmount
            })
        }

        setBalanceAndMaxAmount().catch(e => {})
    }, [merchantBalance, topupInitialAmount, topupReversedAmount]);

    const textAboveBtn = (
        <>
            {strings.YouAreUpdating1} <span className="text-semibold">Rp {submitModalContent.rupiahAmount}</span>
            {isTopupReversal() ? strings.YouAreUpdating2 : strings.YouAreUpdating3} : <span className="text-medium">{submitModalContent.transactionID}</span>
            &nbsp;{strings.For} <span className="text-medium">{"\"" + merchantName + "\""}</span>
        </>
    )
    const topupFooter = (
        <>
            <ModalTextWrapperWithBg data-testid="modal-text-wrapper" 
                alignItems="center"
                container
                sx={{marginTop: "1rem"}}>
                <Grid item xs={1}>
                    <img src={STATIC_ASSETS.INFO_ICON} alt="icon" />
                </Grid>
                <Grid item xs>
                    {
                        isAllFieldCompleted() ?
                        <Box className="border-b-white">{ textAboveBtn }</Box> : <></>
                    }
                    <Box>{modalTextAboveButton}</Box>
                </Grid>
            </ModalTextWrapperWithBg>
            <Grid item xs={12}>
                <Controls.BaseButton id="confirm-btn" data-testid="confirm-btn" onClick={onSubmit} fontsize_={Number(20)} height="56px" text={strings.Submit} 
                    disabled={! isAllFieldCompleted()} />
            </Grid>
        </>
    )

    const topupReversalFooter = (
        <>
            {
                isAllFieldCompleted() ? 
                <ModalTextWrapperWithBg data-testid="modal-text-wrapper" 
                    alignItems="center"
                    container
                    sx={{marginTop: "1rem"}}>
                    <Grid item xs={1}>
                        <img src={STATIC_ASSETS.INFO_ICON} alt="icon" />
                    </Grid>
                    <Grid item xs>
                        <Box>{ textAboveBtn }</Box>
                    </Grid>
                </ModalTextWrapperWithBg>
                : <></>
            }
            <ModalTextWrapper margin="25px 0 10px 0" center>
                <Box>{ strings.ClickConfirmToProceed }</Box>
            </ModalTextWrapper>

            <Grid container justifyContent="space-between" gap={2} alignItems="center">
                <Grid item xs>
                    <Controls.BaseButton data-testid="cancel-btn" 
                        btnborder={`1px solid ${theme.palette.neutral.border}`}
                        bgcolor={theme.palette.neutral.white}
                        textcolor={"#1F1F1F"}
                        onClick={handleClose} 
                        fontsize_={Number(18)} 
                        height="50px" text={strings.Cancel} />
                </Grid>
                <Grid item xs>
                    <Controls.BaseButton id="confirm-btn" data-testid="confirm-btn" 
                        onClick={onSubmit} 
                        fontsize_={Number(18)} 
                        height="50px" text={strings.Confirm} 
                        disabled={! isAllFieldCompleted()} />
                </Grid>
            </Grid>
        </>
    )

    const amountValidatorTxt = [
        strings.Maximum,
        'Rp',
        formatAmount(state.maxRefundAmount),
        strings.CanBeReversed,
        strings.PleaseEnterCorrectAmt
    ].join(' ')

    const headerText = () => {
        switch(topupModalKind) {
            case VcTopupTrxType.partialReversal.id:
                return 'Partial Refund Top-up'
            case VcTopupTrxType.fullReversal.id:
                return 'Full Refund Top-up'
            default:
                return merchantName + ' - Top-up'
            
        }
    }

    return (
        <ModalWrapper data-testid="submit-modal-wrapper" container spacing={2} direction="column" justifyContent={'center'} alignItems={'center'}>
            <CloseIconGrid data-testid="submit-close-icon" item xs={12} alignItems={'right'}>
                <Button onClick={handleClose} variant="text" sx={{padding: "unset", minWidth: "unset", justifyContent: "flex-end"}}>
                    <img id="close-btn" src={STATIC_ASSETS.CLOSE_ICON} alt="close-icon" />
                </Button>
            </CloseIconGrid>
            <ModalHeader data-testid="modal-header" item xs={12}><Box>
                {
                    headerText()
                }
            </Box></ModalHeader>
            
            <Box sx={{display: isTopupReversal() ? "block" : "none", width: "100%", marginBottom: "2rem"}}>
                <VcWalletBox isError={isInsufficientBalance()}>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item xs={1}>
                            <RoundedIconWrapper>
                                <img src={STATIC_ASSETS.WALLET_OUTLINE} alt="wallet" />
                            </RoundedIconWrapper>
                        </Grid>
                        <Grid item xs>
                            <span className="label-text">
                                { strings.AvailableBalance } : 
                            </span>
                            <span className="value-text">
                                { isLoadingBalance ? 'Rp ...' : formatRupiah(merchantBalance) }
                            </span>
                        </Grid>
                        <Grid item xs={1}>
                            <StyledRefreshBtn
                                id="refresh-btn"
                                className={state.disableRefreshBalance ? "disabled" : null} 
                                onClick={state.disableRefreshBalance ? null : refreshBalance}
                                sx={{width: "30px", height: "30px", padding: "2px"}}>
                                <img src={STATIC_ASSETS.REFRESH_ICON} alt="refresh" />
                            </StyledRefreshBtn>
                        </Grid>
                    </Grid>
                </VcWalletBox>
                <Typography 
                    color="#CB2029"
                    sx={{
                        marginTop: "5px",
                        display: isInsufficientBalance() ? 'block' : 'none'
                }}>
                    You have insufficient active balance. Please select partial refund.
                </Typography>
            </Box>
            <Grid container direction="column" gap={3}>
                <Grid item>
                    <div style={{display: 'flex', alignItems: "flex-start", gap: "6px"}}>
                        <StyledInputLabel>{strings.Amount} (IDR)</StyledInputLabel>
                        <Components.HoverTooltip title={"Max Rp " + formatAmount(getMaxTopupAmount())} placement="right" arrow/>
                    </div>
                    <StyledTextField
                        fullWidth
                        value={submitModalContent.rupiahAmount}
                        onChange={handleAmount}
                        placeholder="0"
                        disabled={parseInt(submitModalContent.transactionType) === VcTopupTrxType.fullReversal.id}
                        InputProps={{
                            startAdornment: (
                                <div style={{marginRight: '10px', display: 'flex', columnGap: '10px'}} >
                                    <img src={STATIC_ASSETS.MONEYS_ICON} alt="icon" />
                                    <span>Rp</span>
                                </div>
                            )
                            // maxLength: 10
                        }} 
                        error={! isAmountValid(true)}
                        helperText={isAmountValid(true) ? null : amountValidatorTxt}
                    />
                </Grid>
                <Grid item>
                    <StyledInputLabel variant="h4" sx={{fontWeight: 500}}>{strings.TransactionId}</StyledInputLabel>
                    <StyledTextField
                        fullWidth
                        value={submitModalContent.transactionID}
                        onChange={handleTrxID}
                        placeholder={strings.EnterTransactionID}
                        disabled={isTopupReversal()}
                        InputProps={{
                            startAdornment: prependIcon(STATIC_ASSETS.ARROW_CROSS_ICON),
                            inputProps: {
                                maxLength: 20,
                                minLength: 5
                            }
                        }}
                        error={ ! textFieldMinMaxValidator(submitModalContent.transactionID) }
                        helperText={ textFieldMinMaxValidator(submitModalContent.transactionID) ? null : 'Please put a text between 5 and 20 character length' }
                    />
                </Grid>
                <Grid item>
                    <StyledInputLabel>{strings.Description}</StyledInputLabel>
                    <StyledTextField
                        fullWidth
                        value={submitModalContent.description}
                        onChange={handleDesc}
                        placeholder={strings.EnterDesc}
                        InputProps={{
                            startAdornment: prependIcon(STATIC_ASSETS.HORIZONTAL_DOTS_CIRCLE_ICON),
                            inputProps: {
                                maxLength: 100,
                                minLength: 5
                            }
                        }} 
                        error={ ! textFieldMinMaxValidator(submitModalContent.description, true) }
                        helperText={ textFieldMinMaxValidator(submitModalContent.description, true) ? null : 'Please put a text between 5 and 100 character length' }
                    />
                </Grid>
            </Grid>

            { isTopupReversal() ? topupReversalFooter : topupFooter }
        </ModalWrapper>
    )
}

export default VCTopupFormModal