const LocalData = {
  en: {
    Home: "Home",
    Login: "Login",
    Login_Title: "Welcome to ayoconnect",
    Login_Portal: "Admin Portal",
    Email: "Email",
    Password_Label: "Password",
    PlaceHolder_Email: "e.g. Yourname@gmail.com",
    PlaceHolder_Password: "Your password here",
    Forgot_Password: "Forgot Password",
    Email_empty: "Enter your email",
    Email_Incorrect: "Only use Ayoconnect email",
    Password_empty: "Enter your password",
    Passoword_Incorrect: "Password is incorrect",
    Admin_Portal: "Admin's Portal",
    Set_New_Password: "Set New Password",
    New_Password: "New Password",
    Confirm_New_Password: "Confirm New Password",
    Your_Password_Here: "Your password here",
    Your_Password_Must_Have: "Your password must have",
    Minimum_10_Characters: "Minimum 10 characters",
    Uppercase_Characters: "Uppercase characters(A-Z)",
    Lowercase_Characters: "Lowercase characters(a-z)",
    A_Number: "A number(0-9)",
    Special_Characters: "Special charaters(~*@$#%_>+?)",
    Save: "Save",
    Reset: "Reset",
    Cancel: "Cancel",
    Your_Password_And_Confirmation_Password_Must_Match: "Your password and confirmation password must match",
    Congratulations: "Congratulations!",
    Password_Success_Message: "You are successfully set a new account password. Let's access the Dashboard",
    Login_To_The_Dashboard: "Login to the Dashboard",
    This_URL_Has_Been_Expired_Please_Request_A_New_One: "This URL has been expired. Please request a new one.",
    congratulation: "Congratulations!",
    Login_to_dashboard: "Login to dashboard",
    EscrowAccount: "Escrow account",
    TotalClientBalance: "Total Client Balance",
    EscrowBalance: "Escrow Balance",
    TopUp: "Top-Up",
    Withdrawal: "Withdrawal",
    ShowEntries: "Show Entries",
    Download: "Download",
    TransactionDetails: "Transaction Details",
    Refund: "Refund",
    EscrowTooltip: "Total balance in ayoconnect escrow account",
    ClientTooltip: "Sum total of top-up account balance for all client",
    Showing: "Showing",
    Of: "of",
    Entry: "entry",
    To: "to",
    ToThe: "to the",
    For: "for",
    Are_You_Sure_That: "Are You Sure that",
    You_Want_To_Logout: "You want to Logout ?",
    Logout: "Logout",
    SelectClientName: "Select Client Name",
    SelectName: "Select Name",
    SelectClient: "Select Client",
    Submit: "submit",
    EscroTopUp: "Escrow Top-up",
    EnterAmount: "Enter Amount (IDR)",
    AddDesc: "Add Description",
    EnterDesc: "Enter Description",
    EnterTransactionID: "Enter Transaction ID",
    MoneyCreditEscrow: "This money will be credited in the escrow account.",
    AreYouSure: "Are You Sure?",
    YouAreAdding1: "You are adding",
    YouAreAdding2: " to the escrow account",
    YouWantReverse: "You want to reverse",
    ClickConfirmToProceed: `Click on "Confirm" to proceed`,
    Success: "Success",
    SuccessfullyAdded: " has been successfully added to the Escrow account.",
    SuccessfullyDebited: " has been successfully debited from the Client account.",
    SuccessfullyAddedTo: " has been successfully added to the ",
    SuccessfullyReversedTo: " has been successfully Reversed to the ",
    SuccessfullyAddedClient: " has been successfully added to the Client account.",
    SuccessfullyDebitedClient: " has been successfully debited from the Escrow account.",
    YouAreDebiting1: "You are debiting",
    YouAreDebiting2: " from the escrow account.",
    Confirm: "Confirm",
    Confirmation: "Confirmation",
    Reset_Password: "Reset Password ",
    YouAreAdding2Client: " to the client account.",
    YouAreDebiting2Client: "from the client account.",
    ClientRefund: "Client Refund",
    StartDate: "Start Date",
    EndDate: "End Date",
    TransactionId: "Transaction ID",
    CustSendMobNum: "Customer Mobile Number",
    BenificiaryName: "Beneficiary Bank Name",
    BenificiaryName2: "Beneficiary Name",
    Apply: "Apply",
    Clear: "Clear",
    Disbursement: "Disbursement",
    YouAreUpdating1: "You are updating",
    YouAreUpdating2: " as Refund against Transaction ID",
    YouAreUpdating3: " as Top-up against Transaction ID",
    TransactionType: "Transaction Type",
    EnterBankName: "Enter Bank Name",
    MoneyDebitEscrow: "This money will be debited from the Escrow account.",
    EscrowWithdrawal: "Escrow Withdrawal",
    MoneyCreditClient: "This money will be credited in the Client account.",
    MoneyDebitClient: "This money will be debited in the Client account.",
    SelectDate: "Select Date",
    SetDate: "Set Date",
    EnterTransactionType: "Enter Transaction Type",
    EnterTransactionId: "Enter Transaction Id",
    EnterMobileNumber: "Enter Mobile Number",
    DateAndTime: "Date & Time",
    EmailId: "Email Id",
    CreditDebit: "Credit/Debit",
    MutationType: "Mutation Type",
    Amount: "Amount",
    Description: "Description",
    CustomerMobileNumber: "Customer Mobile number",
    Status: "Status",
    BeneficiaryBankName: "Beneficiary Bank Name",
    Credit: "Credit",
    Debit: "Debit",
    ClientName: "Client Name",
    ProductName: "Product Name",
    BankName: "Bank Name",
    MDRType: "MDR Type",
    TotalMDR: "Total MDR",
    ConfigurationDetails: "Configuration Details",
    SelectDetails: "Select Details",
    SelectProduct: "Select Product",
    SelectBank: "Select Bank",
    SelectMdrType: "Select MDR Type",
    MDRDetails: "MDR Details",
    BankFeesType: "Bank Fees Type",
    AyoconnectFeesType: "Ayoconnect Fees Type",
    BankFees: "Bank Fees",
    AyoconnectFees: "Ayoconnect Fees",
    ClientFeesType: "Client Fees Type",
    ClientFees: "Client Fees",
    EnterBankFees: "Enter Bank Fees",
    EnterClientFees: "Enter Client Fees",
    EnterMdrType: "Enter MDR Type",
    EnterAyoconnectFees: "Enter Ayoconnect Fees",
    Edit: "Edit",
    PricingConfiguration: "Pricing Configuration",
    ClientAccount: "Client Balance",
    ClientConfigurationSetting: "Client Configuration Setting",
    SelectBankFeesType: "Select bank fees type",
    SelectClientFeesType: "Select client fees type",
    SelectAyoconnectFeesType: "Select ayoconnect fees type",
    NoRecordsFound: "No records found",
    Settlement: "Settlement",
    AreYouSureYouWantTo: "Are you sure you want to",
    Exit: "exit",
    VirtualCard: "Virtual Card",
    CardId: "Card ID",
    CustomerDetails: "Customer Details",
    ClientInfo: "Client Info",
    ClientUpdatedBalance: "Client updated Balance",
    AyoUpdatedBalance: "Updated AyoConnect Balance",
    TransactionAmount: "Transaction Amount",
    TransactionStatus: "Transaction Status",
    SettlementStatus: 'Settlement Status',
    Reason: "Reason",
    MerchantId: "Merchant ID",
    CustomerId: "Customer ID",
    CustomerName: "Customer Name",
    CardLimit: "Card Limit",
    RemainingBalance: "Remaining Balance",
    AvailableBalance: "Available Balance",
    TheCurrentRecordEditing: " the current record editing ?",
    TroubleLoggingIn: "Trouble logging in ?",
    EnterYourRegisteredEmailAddress: "Enter your registered email address. We'll send you a link to get back into your account.",
    UploadTime: "Upload Time",
    UploadedFile: "Uploaded File",
    FileSize: "File Size (Max 5 Mb)",
    UploadedBy: "Uploaded By",
    Upload: "Upload",
    SelectFile: "+ Select File",
    EmbeddedPayments: "Embedded Payments",
    paymentMode: "Payment Mode",
    serialNum: "Sn. No.",
    switchName: "Enable/Disable",
    NoPaymentMode: "No Payment Mode",
    Okay: "Okay",
    IsUpdateText: "Are you sure you want save the new settings",
    OK: "OK",
    UpdatedText: "The new configuration has been updated successfully and will reflect immediately",
    TotalSpends: "Total Spends",
    Balance: "Balance",
    BalanceInfo: "Balance Info",
    RefundType: "Refund Type",
    RefundAmount: "Refund Amount",
    TopupDoneVia: "Top-up Done Via",
    Action: "Action",
    ChooseTopupRefund: "Choose Top-up Refund",
    session_end_title_1: "Hellooo...",
    session_end_title_2: "Are you still here?",
    session_end_text: "Please login again to access this dashboard.",
    session_end_button: "LOGIN AGAIN",
    Maximum: "Maximum",
    CanBeReversed: "can be reversed.",
    PleaseEnterCorrectAmt: "Please enter correct amount.",
    PurchaseId: "Purchase ID",
    InstantTransfer: "Instant Transfer",
    TypeToSearch: "Type to search",
    Filter: "Filter",
    Export: "Export",
    DateRange: "Date Range",
    DateRangeRequired: "Date Range Required",
    SelectDateRange: "Select Date Range",
    TransferHistory: {
      SelectMerchant: "Select Merchant",
      SearchMerchant: "Search Merchant",
      Transactions: "Pending Transactions",
      SearchTransactionId: "Search Transaction ID",
      Transaction_Details: "Transaction Details",
      Transaction_Creation_Time: "Transaction Creation Time",
      Transaction_Updated_Time: "Transaction Updated Time",
      Transaction_Id: "Transaction ID",
      MerchantInfo: "Merchant Information",
      BeneficiaryDetails: "Beneficiary Details",
      MerchantName: "Merchant Name",
      MerchantCode: "Merchant Code",
      Supplier: "Supplier",
      BankRefNo: "Bank Reference Number",
      BeneId: "Beneficiary ID",
      BeneName: "Beneficiary Name",
      AcNumber: "Account Number",
      AccountType: "Account Type",
      BeneSwiftCode: "Bank Swift Code",
      BeneBankName: "Beneficiary Bank Name",
      Transaction: "Transaction",
      Update: "Update",
      UpdateStatus: "Update Status",
      UpdateTrxnStatus: "Update Transaction Status",
      Success: "Success",
      Failed: "Failed",
      Cancel: "Cancel",
      Confirm: "Confirm",
      Refund: "Refund",
      trxnRefNo: "Transaction Reference number",
      failureReason: "Failure Reason",
      trxnUpdated: "Transaction Status Updated to",
      AreYouSure: "Are you sure you want to update the status?",
      ConfirmPoints: {
        point1: "Latest status from the bank:",
        point2: "Please make sure the status change aligns with our Bank Mutation record"
      }
    },
    supplierManagement: {
      menuName: "Supplier",
      supplierManagement: "Supplier Management",
      totalClients: "Total Primary Clients",
      bannerLine1: "System is on Maintenance Mode",
      bannerLine2: "We're turning off the Instant Transfer service for all clients. Please follow up with internal/external PIC for turning the service back on.",
      status: "Status",
      lastActive: "Last Active",
      downTime: "Down Time",
      enterMaintenanceMode: "Enter System Maintenance Mode?",
      allSuppliersWillBeDown: "All suppliers will become inactive and unusable for all clients.",
      confirmEnter: "Enter Maintenance Mode",
      deactivate: "Deactivate",
      switchModalMsg1: "Merchants who have",
      switchModalMsg2: "Bank set as their primary supplier will be switched to their secondary supplier.",
      balanceToTransfer1: "Balance in",
      balanceToTransfer2: "to be transferred",
      transferTo: "Transfer To",
      deactivationSuccess: "Supplier Deactivation Success",
      bankHasBeenDeactivated: "Nobu Bank have been deactivated",
      deactivationFailed: "Supplier Deactivation Failed",
      problemOccured: "A problem occurred while deactivating Nobu Bank. Please try again later.",
      maintenanceModeEnabled: "Maintenance Mode Enabled",
      maintenanceModeMsg: "All suppliers have been deactivated, and IT system have entered maintenance mode.",
      supplierActivationSuccess: "Supplier Activation Success",
      bankHasBeenActivated: "Bank have been reactivated",
      reactivate: "Reactivate",
      reactivateMsg1: "Merchants who have",
      reactivateMsg2: "set as their primary supplier will be moved back.",
      reactivateTransferMsg1: "Balance to be transfered to",
      reactivateTransferMsg2: "",
      TotalClients: "Total Clients",
      TotalActiveClientBalance: "Total Active Client Balance",
      tableSub1: "Merchants with",
      tableSub2: " as Primary Supplier",
      merchantName: "Merchant Name",
      merchantCode: "Merchant Code",
      balance: "Balance",
      primarySupplier: "Primary Supplier",
      tooltipText1: "Merchants who have",
      tooltipText2: "set as their primary supplier will be moved back once",
      tooltipText3: "is active.",
      SearchMerchantNameOrCode: "Search Merchant Name or Code",
      back: "back",
      active: "Active",
      inactive: "Inactive",
      viewClients: "View Clients"
    },
  },
  it: {
    supplierManagement: {
      reactivateTransferMsg1: "Saldo yang perlu ditransfer",
      reactivateTransferMsg2: "",
      reactivate: "Aktifkan",
      reactivateMsg1: "Merchant yang pengaturan Supplier Utamanya ada di",
      reactivateMsg2: "akan dipindahkan kembali ke Bank tersebut.",
      deactivationSuccess: "Nonaktifkan Supplier Berhasil",
      bankHasBeenDeactivated: "Bank Nobu sudah dinonaktifkan",
      deactivationFailed: "Nonaktifkan Supplier Gagal",
      problemOccured: "Gagal saat menonaktifkan Nobu Bank. Silakan coba lagi nanti.",
      maintenanceModeEnabled: "Mode Maintenance Diaktifkan.",
      maintenanceModeMsg: "Semua supplier telah dinonaktifkan, dan sistem Instant Transfer telah memasuki mode maintenance.",
      supplierActivationSuccess: "Aktivasi Supplier Berhasil",
      bankHasBeenActivated: "Bank sudah diaktifkan",
      menuName: "Supplier",
      supplierManagement: "Manajemen Supplier",
      totalClients: "Jumlah Klien Primary",
      bannerLine1: "Sistem sedang dalam maintenance",
      bannerLine2: "Layanan Transfer Instan nonaktif untuk semua klien. Harap Follow Up dengan PIC terkait baik internal/eksternal untuk mengaktifkan kembali layanan.",
      status: "Status",
      lastActive: "Terakhir Aktif",
      downTime: "Masa Tidak Aktif",
      enterMaintenanceMode: "Konfirmasi Mode Maintenance?",
      allSuppliersWillBeDown: "Semua Supplier akan menjadi tidak aktif dan tidak dapat digunakan untuk semua klien.",
      confirmEnter: "Masuk ke Mode Maintenance",
      deactivate: "Nonaktifkan",
      switchModalMsg1: "Merchant yang menggunakan Bank",
      switchModalMsg2: "sebagai Supplier Utama akan dialihkan ke supplier sekundernya.",
      balanceToTransfer1: "Saldo di",
      balanceToTransfer2: "yang perlu dipindahkan",
      transferTo: "Transfer ke",
      TotalClients: "Jumlah Klien",
      TotalActiveClientBalance: "Jumlah Saldo Klien Aktif",
      tableSub1: "Merchant yang Primary Suppliernya",
      tableSub2: "",
      merchantName: "Nama Merchant",
      merchantCode: "Kode Merchant",
      balance: "Saldo",
      primarySupplier: "Primary Supplier",
      tooltipText1: "Merchant yang pengaturan Supplier Utamanya ada di",
      tooltipText2: "akan dipindahkan kembali setelah",
      tooltipText3: "tersebut diaktifkan.",
      SearchMerchantNameOrCode: "Cari Nama atau Kode Merchant",
      back: "Kembali",
      active: "Aktif",
      inactive: "Tidak Aktif",
      viewClients: "Lihat Klien"
    },
    TransferHistory: {
      SelectMerchant: "Pilih Merchant",
      SearchMerchant: "Cari Merchant",
      Transactions: "Transaksi Pending",
      SearchTransactionId: "Cari ID Transaksi",
      Transaction_Details: "Detil Transaksi",
      Transaction_Creation_Time: "Waktu Transaksi Dibuat",
      Transaction_Updated_Time: "Waktu Transaksi Terupdate",
      Transaction_Id: "ID Transaksi",
      MerchantInfo: "Informasi Merchant",
      BeneficiaryDetails: "Detil Penerima",
      MerchantName: "Nama Merchant",
      MerchantCode: "Kode Merchant",
      Supplier: "Supplier",
      BankRefNo: "Bank Reference Number",
      BeneId: "ID Penerima",
      BeneName: "Nama Penerima",
      AcNumber: "Nomor Rekening",
      AccountType: "Tipe Akun",
      BeneSwiftCode: "SwiftCode Bank",
      BeneBankName: "Bank Penerima",
      Transaction: "Transaksi",
      Update: "Update",
      UpdateStatus: "Update Status",
      UpdateTrxnStatus: "Update Status Transaksi",
      Success: "Sukses",
      Failed: "Gagal",
      Cancel: "Batal",
      Confirm: "Konfirmasi",
      Refund: "Refund",
      trxnRefNo: "Transaction Reference number",
      failureReason: "Alasan Gagal",
      trxnUpdated: "Update Status Transaksi ke",
      AreYouSure: "Apakah Anda yakin ingin memperbarui status?",
      ConfirmPoints: {
        point1: "Status dari Bank:",
        point2: "Mohon pastikan perubahan status sesuai dengan Mutasi Bank."
      }
    },
    Home: "Beranda",
    session_end_button: "LOGIN LAGI",
    session_end_title_1: "Halooo...",
    session_end_title_2: "Kamu masih disini gak?",
    session_end_text: "Silahkan login lagi untuk mengakses dasbor ini.",
    EnterMdrType: "Masukkan Jenis MDR",
    SelectMdrType: "Pilih Jenis MDR",
    Login: "Masuk",
    Login_Title: "Selamat datang di Ayoconnect",
    Login_Portal: "Portal Merchant",
    Email: "Email",
    Password_Label: "Kata Sandi",
    PlaceHolder_Email: "cth namaanda@gmail.com",
    PlaceHolder_Password: "Kata sandi Anda di sini",
    Forgot_Password: "Lupa Kata Sandi",
    Email_empty: "Masukan Email anda",
    Email_Incorrect: "Hanya bisa gunakan Ayoconnect email",
    Password_empty: "Masukkan Password anda",
    Passoword_Incorrect: "Kata sandi salah",
    Admin_Portal: "Portal Admin",
    Set_New_Password: "Atur Sandi Baru",
    New_Password: "Sandi Baru",
    Confirm_New_Password: "Konfirmasi Sandi Baru",
    Your_Password_Here: "Sandi Anda disini",
    Your_Password_Must_Have: "Kata sandi Anda wajib memiliki",
    Minimum_10_Characters: "Minimal 10 Karakter",
    Uppercase_Characters: "Huruf besar (A-Z)",
    Lowercase_Characters: "Huruf kecil (a-z)",
    A_Number: "Sebuah angka (0-9)",
    Special_Characters: "Karakter spesial (~*@$#%_>+?)",
    Save: "Simpan",
    Reset: "Reset",
    Cancel: "Batal",
    Your_Password_And_Confirmation_Password_Must_Match: "Kata sandi dan sandi konfirmasi harus sesuai",
    Congratulations: "Selamat!",
    Password_Success_Message: "Anda sudah berhasil mengatur sandi baru. Silahkan akses Dasbor",
    Login_To_The_Dashboard: "Masuk ke Dasbor",
    This_URL_Has_Been_Expired_Please_Request_A_New_One: "URL sudah kadaluarsa. Silahkan minta yang baru.",
    congratulation: "Selamat!",
    Login_to_dashboard: "Masuk ke Dashboard",
    EscrowAccount: "Rekening Penampung",
    TotalClientBalance: "Total Saldo Klien",
    EscrowBalance: "Saldo Rekening Penampung",
    TopUp: "Top-Up",
    Withdrawal: "Penarikan",
    ShowEntries: "Tampilkan Entri",
    Download: "Unduh",
    TransactionDetails: "Detil Transaksi",
    Refund: "Refund",
    EscrowTooltip: "Jumlah saldo di rekening penampung Ayoconnect",
    ClientTooltip: "Jumlah total dari isi ulang saldo akun untuk semua klien",
    Showing: "Menampilkan",
    Of: "dari",
    Entry: "entri",
    To: "hingga",
    ToThe: "ke",
    For: "untuk",
    Filter: "Filter",
    Export: "Ekspor",
    DateRange: "Rentang Tanggal",
    DateRangeRequired: "Rentang Tanggal Diperlukan",
    SelectDateRange: "Pilih Rentang Tanggal",
    Are_You_Sure_That: "Apakah Anda yakin itu",
    You_Want_To_Logout: "Anda ingin keluar ?",
    Logout: "Keluar",
    SelectClientName: "Pilih Nama Klien",
    SelectName: "Pilih Nama",
    SelectClient: "Pilih Klien",
    Submit: "kirim",
    EscroTopUp: "Top Up Rekening Penampung",
    EnterAmount: "Masukkan Jumlah (Rp)",
    AddDesc: "Tambah Deskripsi",
    EnterDesc: "Masukkan Deskripsi",
    EnterTransactionID: "Masukkan ID Transaksi",
    MoneyCreditEscrow: "Dana ini akan dikreditkan ke rekening penampung.",
    AreYouSure: "Apakah Kamu Yakin?",
    YouAreAdding1: "Kamu menambahkan",
    YouAreAdding2: " ke rekening penampung",
    YouWantReverse: "Kamu ingin mengembalikan",
    ClickConfirmToProceed: `Klik pada "Konfirmasi" untuk melanjutkan`,
    Success: "Sukses",
    SuccessfullyAdded: " sudah berhasil ditambahkan ke rekeing penampung.",
    SuccessfullyDebited: " sudah berhasil didebit dari Rekening Penampung.",
    SuccessfullyAddedTo: " sudah berhasil ditambahkan ke ",
    SuccessfullyReversedTo: " sudah berhasil dikembalikan ke ",
    SuccessfullyAddedClient: " sudah berhasil ditambahkan ke akun klien.",
    SuccessfullyDebitedClient: " has been successfully debited dari ke akun klien.",
    YouAreDebiting1: "Kamu mendebit",
    YouAreDebiting2: " dari Rekening Penampung.",
    Confirm: "Konfirmasi",
    Confirmation: "Konfirmasi",
    Reset_Password: "Atur ulang kata sandi",
    YouAreAdding2Client: " ke akun Klien.",
    YouAreDebiting2Client: "dari ke akun Klien",
    ClientRefund: "Refund Klien",
    StartDate: "Tanggal Mulai",
    EndDate: "Tanggal Selesai",
    TransactionId: "ID Transaksi",
    CustSendMobNum: "Nomor HP Pelanggan",
    BenificiaryName: "Nama Bank Penerima",
    BenificiaryName2: "Nama Penerima",
    Apply: "Terapkan",
    Clear: "Hapus",
    Disbursement: "Pengiriman",
    YouAreUpdating1: "Anda meng-update",
    YouAreUpdating2: "  sebagai Refund untuk ID Transaksi",
    YouAreUpdating3: "  sebagai Top-up terhadap ID Transaksi",
    TransactionType: "Tipe Transaksi",
    EnterBankName: "Masukkan Nama Bank",
    MoneyDebitEscrow: "Dana ini akan didebet dari rekening penampung.",
    EscrowWithdrawal: "Penarikan dari Rekening Penampung",
    MoneyCreditClient: "Dana ini akan dikreditkan ke akun Klien.",
    MoneyDebitClient: "Dana ini akan didebet dari ke akun Klien.",
    SelectDate: "Pilih Tanggal",
    SetDate: "Atur Tanggal",
    EnterTransactionType: "Pilih Jenis Transaksi",
    EnterTransactionId: "Masukkan ID Transaksi",
    EnterMobileNumber: "Masukkan Nomor HP",
    DateAndTime: "Tanggal & Waktu",
    EmailId: "Alamat Email",
    CreditDebit: "Kredit/Debit",
    MutationType: "Tipe Mutasi",
    Amount: "Jumlah",
    Description: "Deskripsi",
    CustomerMobileNumber: "Customer Mobile number",
    Status: "Status",
    BeneficiaryBankName: "Beneficiary Bank Name",
    Credit: "Kredit",
    Debit: "Debit",
    ClientName: "Name Klien",
    ProductName: "Nama Produk",
    BankName: "Bank Nama",
    MDRType: "Jenis MDR",
    TotalMDR: "Total MDR",
    ConfigurationDetails: "Detail Pengaturan",
    SelectDetails: "Pilih Detail",
    SelectProduct: "Pilih Produk",
    SelectBank: "Pilih Bank",
    MDRDetails: "Detail MDR",
    BankFeesType: "Jenis Biaya Bank",
    AyoconnectFeesType: "Jenis Biaya Ayoconnect",
    SelectBankFeesType: "Pilih Jenis Biaya Bank",
    SelectClientFeesType: "Pilih Jenis Biaya Klien",
    SelectAyoconnectFeesType: "Pilih Jenis Biaya Ayoconnect",
    BankFees: "Biaya Bank",
    AyoconnectFees: "Biaya Ayoconnect",
    ClientFeesType: "Jenis Biaya Klien",
    ClientFees: "Biaya Klien",
    EnterBankFees: "Masukkan Biaya Bank",
    EnterClientFees: "Masukkan Biaya Klien",
    EnterAyoconnectFees: "Masukkan Biaya Ayoconnect",
    Edit: "Atur",
    PricingConfiguration: "Konfigurasi Harga",
    ClientAccount: "Saldo Klien",
    ClientConfigurationSetting: "Konfigurasi Klien",
    NoRecordsFound: "Data Tidak Ditemukan",
    Settlement: "Penyelesaian",
    AreYouSureYouWantTo: "Apakah Anda yakin Anda ingin ",
    Exit: "keluar",
    VirtualCard: "Kartu Virtual",
    CardId: "ID Kartu",
    CustomerDetails: "Detail Pelanggan",
    ClientInfo: "Informasi Client",
    ClientUpdatedBalance: "Saldo Terbaru Client",
    AyoUpdatedBalance: "Saldo Terbaru AyoConnect",
    TransactionAmount: "Jumlah Transaction",
    TransactionStatus: "Status Transaksi",
    SettlementStatus: 'Status Penyelesaian',
    Reason: "Alasan",
    MerchantId: "ID Merchant",
    CustomerId: "ID Pelanggan",
    CustomerName: "Nama Pelanggan",
    CardLimit: "Limit Kartu",
    RemainingBalance: "Saldo Tersisa",
    AvailableBalance: "Saldo Tersedia",
    TheCurrentRecordEditing: "  dari pengeditan data saat ini ?",
    TroubleLoggingIn: "Ada kendala saat masuk ?",
    EnterYourRegisteredEmailAddress: "Masukkan alamat email terdaftar Kamu. Kami akan mengirimkan tautan untuk masuk kembali ke akun Kamu.",
    UploadTime: "Upload Time",
    UploadedFile: "Uploaded File",
    FileSize: "File Size (Max 5 Mb)",
    UploadedBy: "Uploaded By",
    Upload: "Upload",
    SelectFile: "+ Select File",
    EmbeddedPayments: "Pembayaran Tertanam",
    paymentMode: "Mode Pembayaran",
    serialNum: "No. Sn.",
    switchName: "Aktifkan/Nonaktifkan",
    NoPaymentMode: "No Mode Pembayaran",
    Okay: "Kembali",
    IsUpdateText: "Apakah anda yakin untuk menyimpan pengaturan yang baru?",
    OK: "OK",
    UpdatedText: "Konfigurasi baru telah berhasil diperbarui dan diimplementasikan",
    TotalSpends: "Total Pembelanjaan",
    Balance: "Saldo",
    BalanceInfo: "Informasi Saldo",
    RefundType: "Jenis Refund",
    RefundAmount: "Nominal Refund",
    TopupDoneVia: "Top-up Selesai Via",
    Action: "Action",
    ChooseTopupRefund: "Pilih Refund pada Top-up",
    Maximum: "Maksimal",
    CanBeReversed: "dapat dikembalikan.",
    PleaseEnterCorrectAmt: "Silahkan isi dengan nominal yang sesuai.",
    PurchaseId: "Purchase ID",
    InstantTransfer: "Transfer Instan",
    TypeToSearch: "Ketik untuk mencari",
  },
};
export default LocalData;
