import { Box, Grid, InputAdornment, useTheme } from "@mui/material"
import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Controls } from "../../controls"
import { STATIC_ASSETS } from "../../utils/staticAssets";
import { ModalWrapper, ModalHeader, ModalTextWrapper, CloseIconGrid } from "./modal.styles"
import LocalData from "../../utils/localization";
import LocalizedStrings from "react-localization";
import { formatRupiah } from "../../utils/utilFunctions";

const SubmitFormModal = (props) => {
    const { currentLanguage } = useSelector((state) => state.languageData);
    const strings = new LocalizedStrings(LocalData);
    strings.setLanguage(currentLanguage);
    const { setCurrentModal, setData, setClose, modalHeader, modalTextAboveButton, isTooltip, merchantCode } = props
    const btnID = modalHeader?.split(" ").join("-").toLowerCase();
    const theme = useTheme()
    const [submitModalContent, setSubmitModalContent] = useState({
        fieldOne: '',
        fieldTwo: ''
    })
    const dispatch = useDispatch()

    const handleClose = () => {
        setClose(false)
        dispatch(setCurrentModal(0))
    }


    const maxInputAmount = () => {
        let maxInput = 20000000000
        if(merchantCode === "JULOTF") {
            maxInput = 100000000000
        } else if(merchantCode === "PAZEMO") {
            maxInput = 60000000000
        } else if(merchantCode === "AYOFIN" || merchantCode === "ADAKAM") {
            maxInput = 50000000000
        } // endif

        return maxInput
    }

    const handleAmount = (e) => {
        const value = e.target.value
        if (value <= maxInputAmount()) {
            setSubmitModalContent({ ...submitModalContent, fieldOne: value })
        }
    }

    const handleDesc = (e) => {
        const value = e.target.value

        if (value.length <= 100) {
            setSubmitModalContent({ ...submitModalContent, fieldTwo: e.target.value })
        }
    }

    const onSubmit = () => {
        dispatch(setData(
            {
                amount: submitModalContent.fieldOne,
                description: submitModalContent.fieldTwo
            }
        ))
        dispatch(setCurrentModal(1))
    }

    return (
        <ModalWrapper data-testid="submit-modal-wrapper" container spacing={2} direction="column" justifyContent={'center'} alignItems={'center'}>
            <CloseIconGrid id="submit-close-btn" data-testid="submit-close-icon" item xs={12} alignItems={'right'}><img src={STATIC_ASSETS.CLOSE_ICON} alt="close-icon" onClick={handleClose} /></CloseIconGrid>
            <ModalHeader data-testid="modal-header" item xs={12}><Box>{modalHeader}</Box></ModalHeader>
            <Grid item xs={12}>
                <Controls.BaseTextField
                    id={`${btnID}-amnt-text-field`}
                    data-testid="text-field-1"
                    fontsize_={Number(40)}
                    value={submitModalContent.fieldOne}
                    onChange={handleAmount}
                    label={strings.EnterAmount}
                    labelfontweight={theme.typography.fontWeight.medium}
                    labelcolor={theme.palette.text.textSix}
                    type="number"
                    simpletextfield='true' placeholder="00"
                    inputpadding="0px 16px" inputmargin="16px 0 0 0"
                    fontWeight={theme.typography.fontWeight.medium}
                    rootpadding="0" textfieldmargin="0 0 24px 0" lineheight="48.41px"
                    tooltipText={"Max " + formatRupiah(maxInputAmount())}
                    isTooltip={isTooltip}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <h1>Rp</h1>
                            </InputAdornment>
                        ),
                    }} />
            </Grid>
            <Grid item xs={12}>
                <Controls.BaseTextField
                    id={`${btnID}-desc-text-field`}
                    data-testid="text-field-2"
                    value={submitModalContent.fieldTwo}
                    onChange={handleDesc}
                    label={strings.AddDesc}
                    labelfontweight={theme.typography.fontWeight.medium}
                    labelcolor={theme.palette.text.textSix}
                    simpletextfield='true'
                    inputpadding="0px 0px" inputmargin="16px 0 0 0"
                    fontWeight={theme.typography.fontWeight.medium}
                    rootpadding="0" textfieldmargin="0 0 24px 0" lineheight="48.41px"
                />
            </Grid>
            <ModalTextWrapper data-testid="modal-text-wrapper" item xs={12}><Box>{modalTextAboveButton}</Box></ModalTextWrapper>
            <Grid item xs={12}>
                <Controls.BaseButton id={`${btnID}-confirm-btn`} data-testid="confirm-btn" onClick={onSubmit} fontsize_={Number(20)} height="56px" text={strings.Submit} disabled={submitModalContent.fieldOne === '' || submitModalContent.fieldTwo === ''} />
            </Grid>
        </ModalWrapper>
    )
}

export default SubmitFormModal